import React from 'react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import './ComprehensionBlock.css'

interface ComprehensionRow {
  question: string
  answer: string
  expected: string
  result: string
  // Add any other properties as needed
}

interface ComprehensionProps {
  issuesCount?: number
  comprehensionRows: ComprehensionRow[]
}

export function ComprehensionBlock({
  issuesCount,
  comprehensionRows,
}: ComprehensionProps) {
  let passCount = 0
  let failCount = 0
  const total = comprehensionRows.length
  comprehensionRows.forEach((item) => {
    if (item.result === 'PASS') {
      passCount++
    } else if (item.result === 'FAIL') {
      failCount++
    }
  })

  // Determine the badge color based on the majority
  const badgeColor = passCount / total > 0.5 ? 'bg-green-600' : 'bg-red-500'

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <h2>Comprehension</h2>
          <Badge className={`badge ${badgeColor} hover:${badgeColor}`}>
            {passCount}/{total} Correct
          </Badge>
        </div>
      </CardHeader>
      <CardContent>
        <Card>
          <CardContent>
            <Accordion type="single" collapsible className="w-full">
              {comprehensionRows.map((item, index) => (
                <AccordionItem key={index} value={`item-${index + 1}`}>
                  <AccordionTrigger
                    className="relative flex items-center justify-between text-left"
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    <div className="comprehension-question flex items-center">
                      {`${index + 1}. ${item.question}`}
                    </div>
                    <div className="ml-auto pr-3">
                      {' '}
                      {/* Apply ml-auto to push this div to the right */}
                      {item.result === 'PASS' ? (
                        <img
                          src="success.svg"
                          alt="Pass"
                          className="comprehension-icon max-w-none"
                        />
                      ) : item.result === 'FAIL' ? (
                        <img
                          src="fail.svg"
                          alt="Fail"
                          className="comprehension-icon max-w-none"
                        />
                      ) : null}
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="flex">
                      <div className="w-auto pr-4">
                        {item.result === 'PASS' ? (
                          <img
                            src="success.svg"
                            alt="Pass"
                            className="comprehension-icon max-w-none"
                          />
                        ) : (
                          <img
                            src="fail.svg"
                            alt="Fail"
                            className="comprehension-icon max-w-none"
                          />
                        )}
                      </div>
                      <div className="flex-grow">
                        <p
                          className={`text-2xl font-bold text-${
                            item.result === 'PASS' ? 'green' : 'red'
                          }`}
                        >
                          {item.result === 'PASS' ? 'Correct' : 'Incorrect'}
                        </p>
                        <p>Answer: {item.expected}</p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <p>Your response:</p>
                      <div className="comprehension-answer">{item.answer}</div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  )
}

export default ComprehensionBlock
