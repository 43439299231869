import React, { useState } from 'react'
import { Button } from '../ui/button'
import { Progress } from '../ui/progress'
import { Checkbox } from '../ui/checkbox'
import { useNavigate } from 'react-router-dom'
import { RadioGroup, RadioGroupItem } from '../ui/radio-group'
import { Label } from '../ui/label'

interface QuestionProps {
  children: React.ReactNode
  questionNumber: number
  totalQuestions: number
  onSkip: () => void
  onPrevious: () => void
  onNext: () => void
  gotToReport: () => void
}

const Question: React.FC<QuestionProps> = ({
  children,
  questionNumber,
  totalQuestions,
  onSkip,
  onPrevious,
  onNext,
  gotToReport,
}) => {
  const [stage, setStage] = useState<'pre' | 'question' | 'post'>('pre')
  const navigate = useNavigate()

  // const handleGoToReport = () => {
  //   navigate('/report', { state: { data: {} } })
  // }

  switch (stage) {
    case 'pre':
      return <PreQuestion onContinue={() => setStage('question')} />
    case 'question':
      return (
        <QuestionContent
          questionNumber={questionNumber}
          totalQuestions={totalQuestions}
          onSkip={onSkip}
          onPrevious={onPrevious}
          onNext={() =>
            questionNumber === totalQuestions ? setStage('post') : onNext()
          }
          gotToReport={gotToReport}
        >
          {' '}
          {children}{' '}
        </QuestionContent>
      )
    case 'post':
      return <PostQuestion handleGoToReport={gotToReport} />
    default:
      return null
  }
}

const PreQuestion: React.FC<{ onContinue: () => void }> = ({ onContinue }) => (
  <CardLayout>
    <div className="text-lg font-semibold leading-7 text-slate-900">
      Comprehension
    </div>
    <p>
      Answer a few questions about the story you read to help us assess your
      comprehension.
    </p>
    <Button className="btn-solid w-full" onClick={onContinue}>
      Continue
    </Button>
  </CardLayout>
)

const PostQuestion: React.FC<{ handleGoToReport: () => void }> = ({
  handleGoToReport,
}) => (
  <CardLayout>
    <div className="text-lg font-semibold leading-7 text-slate-900">
      Your report is almost ready!
    </div>
    <Button className="btn-solid w-full" onClick={handleGoToReport}>
      Go to your report
    </Button>
  </CardLayout>
)

const QuestionContent: React.FC<QuestionProps> = ({
  questionNumber,
  totalQuestions,
  onSkip,
  onPrevious,
  onNext,
  children,
}) => {
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter' && questionNumber !== totalQuestions) {
      onNext()
    }
  }

  return (
    <CardLayout>
      <div
        className="flex items-center justify-between"
        onKeyDown={handleKeyDown}
      >
        <div className="text-lg font-semibold leading-7 text-slate-900">
          Question {questionNumber} of {totalQuestions}
        </div>
        {/* <Button
          onClick={onSkip}
          className="text-center text-red-500 text-base font-normal leading-normal"
        >
          Skip
        </Button> */}
      </div>
      <Progress value={(questionNumber / totalQuestions) * 100} />
      {children}
      <div className="flex justify-between">
        <Button
          className="btn-solid"
          onClick={onPrevious}
          disabled={questionNumber === 1}
        >
          Previous
        </Button>
        <Button className="btn-solid" onClick={onNext}>
          {questionNumber !== totalQuestions ? 'Continue' : 'Finish'}
        </Button>
      </div>
    </CardLayout>
  )
}

const CardLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="flex h-auto w-full max-w-[640px] flex-col gap-4 rounded-lg border border-slate-300 bg-white p-4 sm:p-6">
    {children}
  </div>
)

export default Question
