import React, { useRef, useState } from 'react'
import { FaPlay, FaStop } from 'react-icons/fa'
import { transcribeAudio } from '../../services/api'
import { useNavigate } from 'react-router-dom'
import { Story, AudioRecordingProps } from '@/types/types'
import { Button } from '../ui/button'
import './AudioRecording.css'
import { set } from 'react-hook-form'
import { SharedStore } from '@/services/shared-store'

const AudioRecording = ({
  story,
  showAudioAlert,
  setIsLoading,
  isLoading,
  isAudioRecordingDisabled,
  countdown,
  setCountdown,
  isProcessing,
  setIsProcessing,
  hideStorySelectionControls,
  setHideStorySelectionControls,
  onError,
}: AudioRecordingProps): JSX.Element => {
  const [isRecording, setIsRecording] = useState(false)
  const [timer, setTimer] = useState(0)
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)
  const audioChunksRef = useRef<Blob[]>([])
  const navigate = useNavigate()
  const [audioUrl, setAudioUrl] = useState<string | null>(null)
  const sharedStore = SharedStore.getInstance()

  const delay = async (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms))

  const startCountdown = async (stream: any, options: any) => {
    // Start countdown
    let count = 5
    setCountdown(count)
    const countdownInterval = setInterval(() => {
      count -= 1
      setCountdown(count)
      if (count === 0) {
        clearInterval(countdownInterval)
        setHideStorySelectionControls?.(true)
        const recorder = new MediaRecorder(stream, options)
        setMediaRecorder(recorder)
        recorder.start()

        // starting recording
        setIsRecording(true)
        setCountdown(null)
        setIsProcessing(false)

        const id = setInterval(() => {
          setTimer((prevTime) => prevTime + 1)
        }, 1000)
        setIntervalId(id)

        recorder.ondataavailable = (e) => {
          // Handle the audio data
          audioChunksRef.current.push(e.data)
        }
      }
    }, 1000)
  }

  const getMimeType = () => {
    const options = { mimeType: 'audio/webm; codecs=opus' }

    // Check for MIME type support and choose accordingly
    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
      // If default is not supported, switch to a Safari-compatible MIME type
      if (MediaRecorder.isTypeSupported('audio/mp4')) {
        console.log('Using audio/mp4 MIME type for media recording.')
        options.mimeType = 'audio/mp4'
      } else {
        // Handle the case where neither MIME type is supported
        console.error('No supported MIME type found for media recording.')
        return
      }
    }

    return options
  }

  const startRecording = async (): Promise<void> => {
    if (navigator.mediaDevices?.getUserMedia !== undefined) {
      showAudioAlert()
      setIsProcessing(true)

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        })
        const options = getMimeType()
        await delay(1000)

        // If microphone access is successful, show the audio alert.
        showAudioAlert()

        // Start countdown
        await startCountdown(stream, options)
      } catch (err) {
        console.error('Error accessing the microphone', err)
        onError(
          'Microphone Error',
          'Error accessing the microphone. Please allow microphone access.'
        )
      }
    }
  }

  const stopRecording = async (): Promise<void> => {
    if (mediaRecorder !== null && mediaRecorder !== undefined) {
      mediaRecorder.stop()
      setIsRecording(false)
      setIsLoading?.(true)
      clearInterval(intervalId as unknown as number)
      setTimer(0)

      const stopRecordingPromise = new Promise<void>((resolve, reject) => {
        setCountdown(null)
        mediaRecorder.onstop = async () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: 'audio/webm; codecs=opus',
          })
          audioChunksRef.current = []
          // Create a URL from the audio blob and set it to the state variable
          const url = URL.createObjectURL(audioBlob)
          setAudioUrl(url)
          const sessionId = localStorage.getItem('sessionId')?.toString() || ''
          sharedStore.addItem(sessionId, url)
          try {
            await transcribeAudio(audioBlob)
            navigate('/comprehension', {
              state: { story },
            })
            console.log('Transcription complete')
            resolve()
          } catch (err) {
            console.error('Error in transcribing audio', err)
            reject(err)
          }
        }
      })

      try {
        await stopRecordingPromise
      } catch (err) {
        // Handle any error that occurred during the asynchronous operations
        console.error(
          'Error during AudioRecording::stopRecording::onstop callback:',
          err
        )
      } finally {
        setIsLoading?.(false)
      }
    }
  }

  // Format timer to MM:SS
  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes < 10 ? '0' + minutes : minutes}:${
      seconds < 10 ? '0' + seconds : seconds
    }`
  }

  return (
    <div className="mt-4 flex w-full flex-col items-start justify-start gap-8 bg-white">
      {isRecording && (
        <div className="w-full items-center">
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="order-first flex">
                <div className="mr-2 flex h-6 w-6 animate-pulse items-center justify-center rounded-full border border-red-500 bg-white">
                  <div className="h-3 w-3 rounded-full bg-red-700"></div>
                </div>
                <div className="text-sm font-semibold text-red-500">
                  Recording...
                </div>
              </div>
              <div className="order-last text-sm font-semibold text-black">
                {formatTime(timer)}
              </div>
            </div>
            <div className="relative flex w-full items-center justify-center">
              <div
                className={`audio-recording-component-recording-wave ${
                  isRecording
                    ? 'audio-recording-component-recording-wave-animation visible'
                    : 'invisible'
                }`}
              >
                <ul>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <Button
        className="!btn-solid w-full !bg-red-600 font-medium"
        onClick={async () => {
          if (isRecording) {
            await stopRecording().catch((err) => {
              console.error('Error stopping recording:', err)
            })
          } else {
            await startRecording().catch((err) => {
              console.error('Error starting recording:', err)
            })
          }
        }}
        disabled={
          isAudioRecordingDisabled
            ? isAudioRecordingDisabled
            : isProcessing || isAudioRecordingDisabled
        }
      >
        {isRecording ? <FaStop /> : <FaPlay />}
        <span className="ml-2">
          {isRecording || isProcessing ? 'Stop Recording' : 'Start Recording'}
        </span>
      </Button>
    </div>
  )
}

export default AudioRecording
