import React from 'react'

import './DictionaryItem.css'

// Example of a DictionaryEntry prop structure
// entry = {
//   audioUrl: 'http://example.com/audio.mp3',
//   title: 'Word',
//   description: 'Definition of the word.'
// }

interface DictionaryEntryProps {
  entry: {
    audioUrl?: string
    word?: string
    definition?: string
  }
}

const DictionaryItem: React.FC<DictionaryEntryProps> = ({ entry }) => {
  const playAudio = () => {
    if (entry.audioUrl) {
      const audio = new Audio(entry.audioUrl)
      audio.play().catch((e) => console.log('Error playing audio:', e))
    }
  }

  return (
    <div className="flex items-center space-x-4 p-4">
      <button
        onClick={playAudio}
        className="audio-play-button flex-shrink-0"
        aria-label={`Play audio for ${entry.word}`}
      >
        <div className="audio-play">
          <img
            width="24"
            height="24"
            src="audio-icon.svg"
            alt={`Play Audio for ${entry ? entry.word : ''}`}
            onClick={playAudio}
          />
        </div>
      </button>
      <div>
        <div className="text-lg font-bold text-gray-900">{entry?.word}</div>
        <p className="text-gray-700">{entry?.definition}</p>
      </div>
    </div>
  )
}

export default DictionaryItem
