import React from 'react'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { useNavigate } from 'react-router-dom'
import './CLBBlock.css'
import { CLBLevels, CLBLevelDescription } from './types'
import clbLevels from '../../../data/clbLevels.json'
import { capitalizeFirstLetter } from '../../../lib/utils'

const levelsData: CLBLevels = clbLevels as CLBLevels

interface GeneralInfo {
  description: string
  current_level: number
  recommended_level: number
}

interface CLBBlockProps {
  general: GeneralInfo
}

export function CLBBlock({ general }: CLBBlockProps) {
  const navigate = useNavigate()
  const { current_level, recommended_level } = general

  const getCLBBlockInfo = () => {
    let descriptionKey: keyof CLBLevelDescription = 'base'
    let backgroundClass = ''
    let buttonLabel = ''
    let highlightColorClass = ''
    let columnTitle = ''
    let displayLevelSuffix = ''

    if (current_level === recommended_level) {
      backgroundClass = 'bg-slate-50'
      highlightColorClass = 'bg-slate-200'
      columnTitle = 'We recommend your next story be at the same level.'
    } else if (current_level > recommended_level) {
      console.log('current_level', current_level)
      backgroundClass = 'bg-red-50'
      highlightColorClass = 'bg-red-500'
      descriptionKey = 'minus'
      displayLevelSuffix = '-'
      columnTitle = `We recommend your next story be at CLB ${recommended_level} or lower.`
      buttonLabel = 'Retry this story at a lower CLB level'
    } else {
      backgroundClass = 'bg-slate-50'
      highlightColorClass = 'bg-green-500'
      descriptionKey = 'plus'
      displayLevelSuffix = '+'
      columnTitle = `We recommend your next story be at CLB ${recommended_level} or higher.`
      buttonLabel = 'Retry this story at a higher CLB level'
    }

    const levelInfo = levelsData[recommended_level.toString()]
    console.log('levelsData', levelsData)
    const clbDescription = levelInfo
      ? levelInfo[descriptionKey]
      : 'No description available'

    const category = capitalizeFirstLetter(levelInfo?.category ?? '')
    const benchmark_ability = capitalizeFirstLetter(
      levelInfo?.benchmark_ability ?? ''
    )

    return {
      backgroundClass,
      buttonLabel,
      columnTitle,
      displayLevelSuffix,
      highlightColorClass,
      clbDescription,
      category,
      benchmark_ability,
    }
  }

  const {
    backgroundClass,
    buttonLabel,
    columnTitle,
    displayLevelSuffix,
    highlightColorClass,
    clbDescription,
    category,
    benchmark_ability,
  } = getCLBBlockInfo()

  const topic = 'replace' //lets get the topic from api for sustainability.  will allow for each report to be loaded independent of history.

  const linkPath = `/create-story/?topic=${encodeURIComponent(
    topic
  )}&level=${recommended_level}`

  const handleNavigate = () => {
    const url = `/create-story/?topic=${encodeURIComponent(
      topic
    )}&level=${recommended_level}`
    navigate(url)
  }

  console.log('clbDescription', clbDescription)

  return (
    <Card className="clb-block">
      <CardHeader title="Current CLB Level Insight" />
      <CardContent className="flex">
        <div
          className={`clb-recommended-section relative w-2/5 ${backgroundClass} mr-10 flex items-start justify-start p-4`}
          style={{
            paddingLeft: '20px',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          <div
            className={`stroke w-1 ${highlightColorClass} flex-shrink flex-grow-0`}
          />
          <div className="flex flex-1 flex-col pl-6">
            <h3 className="text-lg font-semibold">
              CLB {recommended_level}
              {displayLevelSuffix}
            </h3>
            <p>
              {benchmark_ability} {category} Ability
            </p>
          </div>
        </div>

        <div className="w-3/4 px-4">
          <p>
            <strong>{columnTitle}</strong>
          </p>
          <div className="py-4">{clbDescription}</div>
          {/* {buttonLabel && (
        <button onClick={handleNavigate} className="mt-2 bg-slate-50 py-2 px-4 rounded-lg">
          {buttonLabel}
        </button>
      )} */}
        </div>
      </CardContent>
    </Card>
  )
}

export default CLBBlock
