import { Card } from '@/components/ui/card'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import '../../App.css'
import Header from '@/components/Header/Header'
import { AudioRecordingProps } from '@/types/types'
import ReadingCard from '@/components/ReadingCard/ReadingCard'
import LoadingPage from '../loading-page/LoadingPage'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'

export function StoryPage() {
  const { story } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false)

  const handlePrivacyPolicyOpen = () => {
    setIsPrivacyPolicyOpen(true)
  }

  const handlePrivacyPolicyClose = () => {
    setIsPrivacyPolicyOpen(false)
  }

  const emptyAudioRecordingProps: AudioRecordingProps = {
    story: null,
    showAudioAlert: () => void 0,
    setIsLoading: setIsLoading,
    isLoading: isLoading,
    isAudioRecordingDisabled: true,
    countdown: null,
    setCountdown: () => void 0,
    isProcessing: null,
    setIsProcessing: () => void 0,
    onError: (title, message) => void 0,
  }

  return (
    <div className="recording-container mx-auto flex flex-col bg-blue-500 md:min-h-screen">
      {isLoading && <LoadingPage />}
      <div className="p-4 md:h-full md:w-full md:p-8">
        <Card className="flex flex-col gap-6 rounded-[10px] bg-white p-4 shadow md:h-full md:w-full md:p-8">
          <Header
            title="Welcome!"
            line1="Pick your topic, select your reading level, and we’ll create a unique story for you."
            line2="Read the story to us, and we’ll give you a free report."
            embed={true}
            useRelativePositioning={false}
          />
          {/* Main content area */}
          <ReadingCard
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            isAudioRecordingDisabled={true}
            _topic={story}
            audioRecordingProps={emptyAudioRecordingProps}
          />

          <div className="mt-auto flex flex-col items-center justify-between text-sm font-medium leading-5 sm:flex-row">
            <p className="mb-4 sm:mb-0">
              <span className="underline">
                <strong>Readiverse</strong>
              </span>{' '}
              can make mistakes. Consider this when you are reviewing stories
              and reports.
            </p>
            <button
              onClick={handlePrivacyPolicyOpen}
              className="privacy-policy-button underline focus:outline-none"
            >
              Privacy Policy
            </button>
          </div>

          {isPrivacyPolicyOpen && (
            <div className="fixed inset-0 z-50 overflow-y-auto">
              <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
                <div
                  className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                  aria-hidden="true"
                ></div>

                {/* This empty span is important for centering the modal contents */}
                <span
                  className="hidden sm:inline-block sm:h-screen sm:align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>

                <AlertDialog onOpenChange={setIsPrivacyPolicyOpen}>
                  <div className="sm:max-full inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:align-middle">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                          <AlertDialogTitle
                            style={{
                              width: '100%', // Width to fill the container
                              height: 'auto', // Height to wrap the content, set explicitly if needed
                              padding: '16px 24px', // Padding as specified
                              opacity: 1, // Assuming it should be fully visible, as opacity: 0 would make it invisible
                            }}
                            className="px-6 text-xl font-semibold text-gray-900"
                          >
                            Privacy Policy
                          </AlertDialogTitle>

                          <div
                            className="mt-2 px-6"
                            style={{ maxHeight: '60vh', overflowY: 'auto' }}
                          >
                            <div className="max-h-60 overflow-y-auto">
                              <h1>Privacy Policy of Readiverse</h1>
                              <p>
                                Readiverse operates{' '}
                                <a
                                  href="https://www.readiverse.ca"
                                  className="text-blue-500"
                                >
                                  www.readiverse.ca
                                </a>{' '}
                                website, which provides the SERVICE.
                              </p>
                              <p>
                                This page is used to inform website visitors
                                regarding our policies with the collection, use,
                                and disclosure of Personal Information if anyone
                                decided to use our Service, the Readiverse
                                website.
                              </p>
                              <p>
                                If you choose to use our Service, then you agree
                                to the collection and use of information in
                                relation with this policy. The Personal
                                Information that we collect are used for
                                providing and improving the Service. We will not
                                use or share your information with anyone except
                                as described in this Privacy Policy.
                              </p>
                              <p>
                                The terms used in this Privacy Policy have the
                                same meanings as in our Terms and Conditions,
                                which is accessible at www.readiverse.ca, unless
                                otherwise defined in this Privacy Policy.
                              </p>
                              <br />
                              <h2>Information Collection and Use</h2>
                              <p>
                                For a better experience while using our Service,
                                we may require you to provide us with certain
                                personally identifiable information, including
                                but not limited to your name, phone number, and
                                postal address. The information that we collect
                                will be used to contact or identify you.
                              </p>
                              <br />
                              <h2>Log Data</h2>
                              <p>
                                We want to inform you that whenever you visit
                                our Service, we collect information that your
                                browser sends to us that is called Log Data.
                                This Log Data may include information such as
                                your computer&apos;s Internet Protocol
                                (&quot;IP&quot;) address, browser version, pages
                                of our Service that you visit, the time and date
                                of your visit, the time spent on those pages,
                                and other statistics.
                              </p>
                              <br />
                              <h2>Cookies</h2>
                              <p>
                                Cookies are files with small amount of data that
                                is commonly used an anonymous unique identifier.
                                These are sent to your browser from the website
                                that you visit and are stored on your
                                computer&apos;s hard drive.
                              </p>
                              <p>
                                Our website uses these &quot;cookies&quot; to
                                collection information and to improve our
                                Service. You have the option to either accept or
                                refuse these cookies, and know when a cookie is
                                being sent to your computer. If you choose to
                                refuse our cookies, you may not be able to use
                                some portions of our Service.
                              </p>
                              <br />
                              <h2>Service Providers</h2>
                              <p>
                                We may employ third-party companies and
                                individuals due to the following reasons:
                              </p>
                              <ul>
                                    <li>To facilitate our Service;</li>    
                                <li>To provide the Service on our behalf;</li>
                                    
                                <li>To perform Service-related services; or</li>
                                    
                                <li>
                                  To assist us in analyzing how our Service is
                                  used.
                                </li>
                              </ul>
                              <p>
                                We want to inform our Service users that these
                                third parties have access to your Personal
                                Information. The reason is to perform the tasks
                                assigned to them on our behalf. However, they
                                are obligated not to disclose or use the
                                information for any other purpose.
                              </p>
                              <br />
                              <h2>Security</h2>
                              <p>
                                We value your trust in providing us your
                                Personal Information, thus we are striving to
                                use commercially acceptable means of protecting
                                it. But remember that no method of transmission
                                over the internet, or method of electronic
                                storage is 100% secure and reliable, and we
                                cannot guarantee its absolute security.
                              </p>
                              <br />
                              <h2>Links to Other Sites</h2>
                              <p>
                                Our Service may contain links to other sites. If
                                you click on a third-party link, you will be
                                directed to that site. Note that these external
                                sites are not operated by us. Therefore, we
                                strongly advise you to review the Privacy Policy
                                of these websites. We have no control over, and
                                assume no responsibility for the content,
                                privacy policies, or practices of any
                                third-party sites or services.
                              </p>
                              <p>Children&apos;s Privacy</p>
                              <p>
                                Our Services do not address anyone under the age
                                of 13. We do not knowingly collect personal
                                identifiable information from children under 13.
                                In the case we discover that a child under 13
                                has provided us with personal information, we
                                immediately delete this from our servers. If you
                                are a parent or guardian and you are aware that
                                your child has provided us with personal
                                information, please contact us so that we will
                                be able to do necessary actions.
                              </p>
                              <br />
                              <h2>Changes to This Privacy Policy</h2>
                              <p>
                                We may update our Privacy Policy from time to
                                time. Thus, we advise you to review this page
                                periodically for any changes. We will notify you
                                of any changes by posting the new Privacy Policy
                                on this page. These changes are effective
                                immediately, after they are posted on this page.
                              </p>
                              <p>
                                Our Privacy Policy was created with the help of
                                the{' '}
                                <a href="https://www.privacypolicytemplate.net">
                                  Privacy Policy Template
                                </a>
                                .
                              </p>
                              <br />
                              <h2>Contact Us</h2>
                              <p>
                                If you have any questions or suggestions about
                                our Privacy Policy, do not hesitate to contact
                                us at{' '}
                                <span className="text-blue-500 underline">
                                  support@readiverse.ca
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <AlertDialogFooter className="flex justify-end bg-gray-50 px-4 py-3">
                      <button
                        className="inline-flex items-center justify-center rounded-tl-md bg-gray-200 px-4 py-2 text-sm font-medium text-black hover:bg-gray-300"
                        onClick={handlePrivacyPolicyClose}
                        style={{
                          width: '75px', // Fixed width
                          height: '40px', // Fixed height
                          borderRadius: '6px 0 0 0', // Custom border radius
                          opacity: 1, // Opacity (Note: Figma's opacity at 0 means fully transparent which seems incorrect here)
                        }}
                      >
                        Got it
                      </button>
                    </AlertDialogFooter>
                  </div>
                </AlertDialog>
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  )
}

export default StoryPage
