import React from 'react'
import { Link } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { useTranslation } from 'react-i18next'

import '../../App.css'
import './HomePage.css'
import { Button } from '@/components/ui/button'
import HomeHeader from '@/pages/home-page/Components/HomeHeader/HomeHeader'
import { Card, CardContent } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { createSession } from '@/services/api'
import { writeToLocalStorage } from '@/helpers/localstorage'

const formSchema = z.object({
  email: z.string().email({
    message: 'Please enter a valid email address.',
  }),
})

export function HomePage() {
  const { t } = useTranslation()
  const topics = ['Ramen noodles', 'Minecraft', 'Space', 'Flowers']

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  })

  const handleCreateStory = async () => {
    try {
      const sessionId: string = await createSession()
      // console.log('Session ID:', sessionId)
      writeToLocalStorage('sessionId', sessionId)
    } catch (error) {
      console.error('Error creating session:', error)
    }
  }

  return (
    <div id="home-page">
      <HomeHeader />
      {/* Section 1 */}
      <div className="mx-auto flex" id="leaderboard">
        <div className="mx-4 max-w-[1200px] flex-1 sm:mx-6 md:mx-auto">
          <h1>
            Improve your
            <br />
            <span className="outer-span">
              <span className="inner-span">English</span>
            </span>
            skills
          </h1>

          <Card className="mx-auto mt-20 max-w-[630px] overflow-hidden rounded-[0.5rem] border bg-background shadow-md md:shadow-xl">
            <CardContent className="p-4 text-center md:p-8">
              <p className="text-base font-bold md:text-lg">
                {t('homePage.section1.testYourAbilities')}
              </p>

              <div id="topics" className="mt-10 flex flex-wrap justify-between">
                {topics.map((topic, index) => (
                  <Link
                    key={index}
                    to={`/create-story/${topic}`}
                    className="mb-4 w-full px-2 md:mb-0 md:w-auto md:px-0"
                    onClick={handleCreateStory}
                  >
                    <Button className="topic-link btn-outline h-11 w-full gap-2.5 px-4">
                      {topic}
                    </Button>
                  </Link>
                ))}
              </div>

              <p className="mb-4 mt-4 text-sm md:text-base">Or</p>

              <Link
                to="/create-story"
                id="create-own-story"
                onClick={handleCreateStory}
              >
                <Button className="btn-solid w-full" id="create-story-btn">
                  Create a story
                </Button>
              </Link>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Section 2 */}
      <div className="mt-20">
        <h2 className="mb-6 text-center text-xl font-semibold">
          {t('homePage.section2.howItWorksTitle')}
        </h2>

        <div className="container mx-auto max-w-[1000px]">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <div className="flex flex-col p-4">
              <Card className="h-full overflow-hidden rounded-[0.5rem] border bg-background shadow-md md:shadow-xl">
                <CardContent className="p-6 text-center">
                  <img
                    src="stories.png"
                    alt="Stories"
                    className="mx-auto block"
                  />
                  <p className="pt-6 text-lg">
                    {t('homePage.section2.uniqueStories')}
                  </p>
                  <p className="text-sm">
                    {t('homePage.section2.uniqueStoriesDescription')}
                  </p>
                </CardContent>
              </Card>
            </div>
            {/* Second Column */}
            <div className="flex-1 p-4 text-center">
              <Card className="h-full overflow-hidden rounded-[0.5rem] border bg-background shadow-md md:shadow-xl">
                <CardContent className="p-6 text-center">
                  <img src="score.png" alt="Score" className="mx-auto block" />
                  <p className="pt-6 text-lg">
                    {t('homePage.section2.individualScore')}
                  </p>
                  <p className="text-sm">
                    {t('homePage.section2.individualScoreDescription')}
                  </p>
                </CardContent>
              </Card>
            </div>
            {/* Third Column */}
            <div className="flex-1 p-4 text-center">
              <Card className="h-full overflow-hidden rounded-[0.5rem] border bg-background shadow-md md:shadow-xl">
                <CardContent className="p-6 text-center">
                  <img
                    src="progress.png"
                    alt="Progress"
                    className="mx-auto block"
                  />
                  <p className="pt-6 text-lg">
                    {t('homePage.section2.trackYourProgress')}
                  </p>
                  <p className="text-sm">
                    {t('homePage.section2.trackYourProgressDescription')}
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* Section 3 */}
      <div id="home_clb" className="mt-20">
        <h2 className="mb-6 text-center text-xl font-semibold">
          Canadian Language Benchmark
        </h2>
        <div className="container mx-auto max-w-[1000px]">
          <div className="mx-4 max-w-[1200px] flex-1 sm:mx-6 md:mx-auto">
            <div className="flex flex-col p-4">
              <Card className="h-full overflow-hidden rounded-[0.5rem] border bg-background shadow-md md:shadow-xl">
                <CardContent className="p-6">
                  <section
                    id="main-content"
                    className="flex flex-col space-y-4"
                  >
                    <article className="text-sm">
                      <p>
                        The Canadian Language Benchmarks (CLB) standard is a
                        descriptive scale of language ability in English as a
                        Second Language (ESL) written as 12 benchmarks or
                        reference points along a continuum from basic to
                        advanced. The CLB standard reflects the progression of
                        the knowledge and skills that underlie basic,
                        intermediate and advanced ability among adult ESL
                        learners.
                      </p>
                    </article>
                    <article className="text-sm">
                      <div className="break-after-left">
                        The Canadian Language Benchmarks are:
                      </div>
                      <ul className="list-outside list-disc pl-5">
                        <li>
                          a set of descriptive statements about successive
                          levels on the continuum of language ability,
                        </li>
                        <li>
                          a description of communicative competencies and
                          performance tasks through which learners demonstrate
                          application of language knowledge (i.e., competence)
                          and skill (i.e., ability),
                        </li>
                        <li>
                          a national standard for planning curricula for
                          language instruction in a variety of contexts,
                        </li>
                        <li>
                          a framework of reference for learning, teaching,
                          programming, and assessing adult ESL in Canada.
                        </li>
                      </ul>
                    </article>
                    <article className="text-sm">
                      <div className="break-after-left">
                        The Canadian Language Benchmarks are NOT:
                      </div>
                      <ul className="list-outside list-disc pl-5">
                        <li>
                          a description of the discrete elements of knowledge
                          and skills that underlie communicative competence
                          (such as specific grammatical structures, elements of
                          pronunciation, vocabulary items, micro-functions,
                          cultural conventions),
                        </li>
                        <li>a curriculum,</li>
                        <li>an assessment.</li>
                      </ul>
                    </article>
                    <article className="text-sm">
                      <div className="break-after-left">
                        From{' '}
                        <a
                          href="https://www.language.ca/resourcesexpertise/on-clb/"
                          target="_blank"
                          title="https://www.language.ca/resourcesexpertise/on-clb/"
                          className="text-blue-600 visited:text-purple-600 hover:text-blue-800"
                          rel="noreferrer"
                        >
                          Center for Canadian Language Benchmarks
                        </a>
                      </div>
                    </article>
                  </section>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* Section 4 */}
      <div
        id="circles"
        className="h-128 relative mx-auto max-w-[1000px] overflow-hidden rounded-lg"
      >
        <div className="p-8">
          <img
            src="circles.svg"
            alt="Background"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <div className="get-started mb-4 text-sky-800">
              <span className="outer-span">
                <span className="inner-span">
                  <a
                    href="/create-story"
                    className="block no-underline hover:bg-sky-800 hover:text-white"
                    onClick={handleCreateStory}
                  >
                    Get Started
                  </a>
                </span>
              </span>
            </div>

            <Link
              to="/create-story"
              id="create-own-story"
              onClick={handleCreateStory}
            >
              <Button
                id="create-story"
                className="btn-solid px-3 py-2"
                onClick={handleCreateStory}
              >
                Create a story
              </Button>
            </Link>
          </div>
          <div className="text-1 text">
            <p>Get meaningful feedback</p>
          </div>

          <div className="text-2 text">
            <p>Answer comprehension questions</p>
          </div>

          <div className="text-3 text">
            <p>Read Stories</p>
          </div>

          <img src="circle-image-1.png" className="image-1 image" />
          <img src="circle-image-2.png" className="image-2 image" />
          <img src="circle-image-3.png" className="image-3 image" />
          <img src="circle-image-4.png" className="image-4 image" />
          <img src="circle-image-5.png" className="image-5 image" />
          <img src="circle-image-6.png" className="image-6 image" />
          <img src="circle-image-7.png" className="image-7 image" />
        </div>
      </div>

      {/* Section 5 */}
      <div id="home-footer" className="mt-20 flex flex-col md:flex-row">
        <div className="flex-1 px-4 py-4">
          <div className="mx-auto flex w-full max-w-md flex-col">
            <h2 className="mb-6 text-center text-3xl md:text-left md:text-6xl">
              Disclaimer
            </h2>
            {/* <div>
              <Label htmlFor="email" className="text-left">
                Email
              </Label>
              <Input id="email" defaultValue="Your Email Address" />
            </div> */}
            <p className="mb-6 mt-6 text-center md:text-left">
              This report is generated with the help of Artificial Intelligence
              (AI) to analyze your test results and provide tailored insights.
              While our AI aims for accuracy, please consider its
              recommendations as part of a comprehensive learning approach.
            </p>
            {/* <Button className="w-full btn-solid">Sign Up</Button> */}
          </div>
        </div>
        <div className="max-auto flex-1 bg-sky-800 px-4 py-4">
          <div className="mx-auto flex w-full max-w-md flex-col text-white">
            <h2
              className="text-center text-3xl md:text-left md:text-6xl"
              style={{ marginBottom: '24px' }}
            >
              Embark on a language journey
            </h2>
            <p>
              Readiverse is free! We want you to help you improve. If you have
              questions or feedback, get in touch with us at
              support@readiverse.ca
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
