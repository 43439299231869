import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from './pages/home-page/HomePage'
import { FormProvider, useForm } from 'react-hook-form'
import StoryPage from './pages/story-page/StoryPage'
import RecordingPage from './pages/recording-page/RecordingPage'
import Comprehension from './pages/comprehension-page/Comprehension'
import ReportPage from './pages/report-page/Report'

const AppRouter: React.FC = () => {
  const methods = useForm()

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <FormProvider {...methods}>
              <HomePage />
            </FormProvider>
          }
        />
        <Route
          path="/create-story/:story?"
          element={
            <FormProvider {...methods}>
              <StoryPage />
            </FormProvider>
          }
        />
        <Route path="/recording" element={<RecordingPage />} />
        <Route path="/comprehension" element={<Comprehension />} />
        <Route path="/report" element={<ReportPage />} />
      </Routes>
    </Router>
  )
}

export default AppRouter
