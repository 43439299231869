export class SharedStore {
  private static instance: SharedStore
  private store: Map<string, any>

  private constructor() {
    this.store = new Map<string, any>()
  }

  public static getInstance(): SharedStore {
    if (!SharedStore.instance) {
      SharedStore.instance = new SharedStore()
    }

    return SharedStore.instance
  }

  public addItem(key: string, value: any): void {
    this.store.set(key, value)
  }

  public removeItem(key: string): void {
    this.store.delete(key)
  }

  public getLastKey(): string[] {
    return Array.from(this.store.keys())
  }

  public getAllItems(): any[] {
    return Array.from(this.store.values())
  }

  public getItem(key: string): any {
    return this.store.get(key)
  }

  public clear(): void {
    this.store.clear()
  }
}
