import React, { useEffect, useState } from 'react'
import { Card, CardContent } from '@/components/ui/card'
import Header from './components/Header'
import Footer from './components/Footer'
import CreateUser from './components/CreateUser'
import FullReport from './components/FullReport'
import CLBBlock from './components/CLBBlock'
import ComprehensionBlock from './components/ComprehensionBlock'
import ReadingInsights from './components/ReadingInsights'
import reportData from '../../data/reportData.json'
import { ReportDataType } from './types'
import { useLocation } from 'react-router-dom'
import './Report.css'
import { report } from 'process'
import { SharedStore } from '@/services/shared-store'

// Assuming the structure of your JSON matches the provided object
export function ReportPage() {
  const [selectedItem, setSelectedItem] = useState('item-1')
  const [reportDataState, setReportDataState] = useState<ReportDataType | null>(
    null
  )

  const location = useLocation()
  const { state } = location
  const { data, story } = state || {}

  useEffect(() => {
    if (data) {
      setReportDataState(data.report)
    }
  }, [data])

  // enable this useEffect to use the reportData.json file and disable the above one
  // useEffect(() => {
  //   if (reportData) {
  //     console.log('this is called')
  //     setReportDataState(reportData)
  //   }
  // }, [reportData])

  const handleNavigateToAnchor = (itemValue: string) => {
    setSelectedItem(itemValue)
    const anchorElement = document.getElementById(itemValue)
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const readingIssuesCount = reportDataState?.mistakes
  const comprehensionItems = reportDataState?.comprehension || []

  const score = reportDataState?.overall_score

  return (
    <div className="mx-auto flex h-screen max-w-full flex-col">
      <Header title="Your Report" />

      {/* Container switches from column to row layout at the 'lg' breakpoint */}
      <div className="bg-report flex flex-grow flex-col lg:flex-row">
        {/* Left Column */}
        {/* Adjusts to take full width on small screens and specific max width on larger screens */}
        <div className="mx-auto mb-4 w-full p-2 lg:mx-2 lg:mb-0 lg:max-w-[480px] lg:p-4">
          {reportDataState && (
            <CreateUser
              reportData={reportDataState}
              /*readingIssuesCount={readingIssuesCount}*/
              /* comprehensionItems={comprehensionItems}*/
              onNavigateToAnchor={handleNavigateToAnchor}
              /* score={score}*/
            />
          )}
          <FullReport />
        </div>

        {/* Right Column */}
        {/* Grows to fill available space, with padding adjusted for screen size */}
        <div className="flex flex-col p-2 lg:mx-2 lg:p-4">
          <Card>
            <CardContent>
              <a id="item-1"></a>
              {reportDataState?.general && (
                <div className="mb-6 mt-6">
                  <CLBBlock general={reportDataState?.general} />
                </div>
              )}

              {/* <a id="item-2"></a>
              {reportDataState && story?.content && (
                <div className="mb-6">{story?.content}</div>
              )} */}

              <a id="item-2"></a>
              {reportDataState && <ReadingInsights data={reportDataState} />}

              <a id="item-3"></a>
              {reportDataState?.comprehension && (
                <div className="mb-6">
                  <ComprehensionBlock comprehensionRows={comprehensionItems} />
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ReportPage
