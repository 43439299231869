interface DictionaryEntry {
  word: string
  definition: string
  audioUrl?: string
}

interface readingRow {
  word: string
  position: number
  type: string
}

// Function to fetch dictionary entries for each word in readingRows
export const fetchDictionaryEntries = async (
  readingRows: string[]
): Promise<DictionaryEntry[]> => {
  const fetchEntryPromises = readingRows.map(async (word) => {
    if (word === '-') {
      return {
        word: word,
        definition: 'No definition found.',
        audioUrl: '',
      }
    }
    const url = `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`
    try {
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error(`Failed to fetch entry for word: ${word}`)
      }
      const data = await response.json()
      // Assuming the dictionary API returns an array of entries, and we're interested in the first one
      const entry = data[0]
      const definition = entry.meanings[0].definitions[0].definition
      let audioUrl = ''
      //pronunciation should be the same so we should be able to pick any pronunciation.
      //loop until you find one.
      for (const phonetic of entry.phonetics) {
        if (phonetic?.audio) {
          audioUrl = phonetic.audio
          break // Stop the loop once a non-empty audio URL is found
        }
      }

      return {
        word: word,
        definition,
        audioUrl,
      }
    } catch (error) {
      console.error(`Error fetching dictionary entry for ${word}:`, error)

      return {
        word: word,
        definition: 'No definition found.',
        audioUrl: '',
      }
    }
  })

  const dictionaryEntries = await Promise.all(fetchEntryPromises)
  return dictionaryEntries
}
