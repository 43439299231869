import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button } from '@/components/ui/button'
import { SocialDialog } from './SocialDialog'
import './Header.css'
import { clearLocalStorage, writeToLocalStorage } from '@/helpers/localstorage'
import { createSession } from '@/services/api'

interface HeaderProps {
  title: string
}

export function Header({ title }: HeaderProps) {
  const navigate = useNavigate()

  const handleSaveAndClose = async () => {
    clearLocalStorage()
    try {
      const sessionId: string = await createSession()
      // console.log('Session ID:', sessionId)
      writeToLocalStorage('sessionId', sessionId)
    } catch (error) {
      console.error('Error creating session:', error)
      // handle error here
    }
    navigate('/create-story')
  }

  return (
    <div id="report-header-container" className="w-full bg-white text-black">
      <div className="flex items-center justify-between">
        <h1>{title}</h1>

        <div className="flex space-x-2">
          {/* <SocialDialog></SocialDialog> */}
          <Button
            onClick={handleSaveAndClose}
            className="rounded-lg bg-sky-800 px-3 py-2 text-white hover:bg-sky-900"
          >
            Save and Close
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Header
