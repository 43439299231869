import {
  checkTokenExpiration,
  getCookie,
  readFromLocalStorage,
} from '../helpers/localstorage'
import {
  type AuthState,
  type AuthAction,
  SET_USER,
  LOGOUT,
} from '../types/types'

const state = JSON.parse(readFromLocalStorage('state') ?? 'null') as AuthState

const initialState: AuthState = {
  user: state?.user ?? null,
  isLoggedIn: state?.isLoggedIn ?? false,
}

export const authReducer = (
  state = initialState,
  action: AuthAction
): AuthState => {
  const token = getCookie('token')
  const user = getCookie('user')

  const isLoggedIn = Boolean(
    token !== null &&
      token !== undefined &&
      token !== '' &&
      user !== null &&
      user !== undefined &&
      user !== '' &&
      checkTokenExpiration(token)
  )

  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        isLoggedIn,
      }
    case LOGOUT:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
      }
    default:
      return {
        ...state,
      }
  }
}
