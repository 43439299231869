import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import './CreateUser.css'
import { ReportDataType } from './../types'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'

interface ComprehensionItem {
  question: string
  answer: string
  expected: string
  result: string
}

interface CreateUserProps {
  // readingIssuesCount?: number
  // comprehensionItems?: ComprehensionItem[]
  // score?:
  reportData: ReportDataType
  onNavigateToAnchor: (itemValue: string) => void
}

export function CreateUser({
  // readingIssuesCount,
  // comprehensionItems,
  // score,
  reportData,
  onNavigateToAnchor,
}: CreateUserProps) {
  const totalItems = reportData.comprehension
    ? reportData.comprehension.length
    : 0
  const correctItems = reportData.comprehension
    ? reportData.comprehension.filter((item) => item.result === 'PASS').length
    : 0
  const [selectedItem, setSelectedItem] = useState('item-1')

  const handleItemClick = (itemValue: any) => {
    setSelectedItem(itemValue === selectedItem ? null : itemValue)
    onNavigateToAnchor(itemValue)
  }

  let passCount = 0
  let failCount = 0
  const total = reportData.comprehension?.length
    ? reportData.comprehension?.length
    : 0

  reportData.comprehension?.forEach((item) => {
    if (item.result === 'PASS') {
      passCount++
    } else if (item.result === 'FAIL') {
      failCount++
    }
  })

  let badgeColor
  if (total > 0) {
    badgeColor = passCount / total > 0.5 ? 'bg-green-600' : 'bg-red-500'
  }

  return (
    <Card>
      <CardContent>
        {/* Header with title on the left and button on the right */}
        <div id="create-user" className="flex items-center justify-between">
          <div>
            <h2>Create User</h2>
            <p className="text-sm">{"Track your progress - It's free"}</p>
          </div>

          {/* <Button>Sign Up</Button> */}
        </div>
        <div>
          <h2>Overall score: {reportData.overall_score}</h2>
          <h3 className="mb-1 mt-1">
            Reading score: {reportData.reading_score}
          </h3>
          <h3>Comprehension score: {reportData.comprehension_score}</h3>
        </div>
        <hr className="my-4" />

        <div id="user-nav" className="w-full">
          <div
            className={`nav-anchor flex cursor-pointer items-center justify-between p-2 ${
              selectedItem === 'item-1' ? 'highlighted' : ''
            }`}
            onClick={() => {
              handleItemClick('item-1')
              onNavigateToAnchor('item-1')
            }}
          >
            <span>General</span>
            <img src="chevron.svg" alt="Click to section" />
          </div>
          <div
            className={`nav-anchor flex cursor-pointer items-center justify-between gap-4 p-2 ${
              selectedItem === 'item-2' ? 'highlighted' : ''
            }`}
            onClick={() => {
              handleItemClick('item-2')
              onNavigateToAnchor('item-2') // Call the callback to navigate to the anchor
            }}
          >
            <span>Reading Insights</span>
            {reportData.mistakes && (
              <Badge className="badge ml-auto bg-red-500 hover:bg-red-500">
                {reportData.mistakes} Issues
              </Badge>
            )}
            <img src="chevron.svg" alt="Click to section" />
          </div>
          <div
            className={`nav-anchor flex cursor-pointer items-center justify-between gap-4 p-2 ${
              selectedItem === 'item-3' ? 'highlighted' : ''
            }`}
            onClick={() => {
              handleItemClick('item-3')
              onNavigateToAnchor('item-3')
            }}
          >
            <span>Comprehension</span>

            {reportData.comprehension && (
              <Badge
                className={`badge ml-auto ${badgeColor} hover:${badgeColor}`}
              >
                {correctItems}/{totalItems} correct
              </Badge>
            )}
            <img src="chevron.svg" alt="Click to section" />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default CreateUser
