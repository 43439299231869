import React from 'react'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import './FullReport.css'

export function FullReport({}) {
  return (
    <Card className="mt-4">
      <CardHeader>
        <h4 className="verified-report-title">Verified Reports Coming Soon</h4>
      </CardHeader>
      <CardContent>
        <div className="report-row flex items-center">
          <div className="image-container flex-shrink-0 pr-4">
            <img
              src="human-verified.png"
              alt="Human Verified"
              className="h-auto max-w-full"
            />
          </div>

          <div className="flex-grow">
            <h2 className="p-human-verified-title">Human verified</h2>
            <p className="p-human-verified-body">
              Your report verified by a human
            </p>
          </div>
        </div>

        {/* <div className="report-row flex items-center border border-red-500">
          <div className="flex-shrink-0 pr-4 image-container">
            {' '}
            <img
              src="infographics.svg"
              alt="Infographic"
              className="max-w-full h-auto"
            />
          </div>
          <div className="flex-grow">
            {' '}
            <p className="text-lg font-bold">Detailed report</p>
            <p>
              Details that can help you build tailored lessons with your tutor.
            </p>
          </div>
        </div> */}

        <div className="report-row flex items-center">
          <div className="image-container flex-shrink-0 pr-4">
            {' '}
            <img
              src="multi-colored-chart.svg"
              alt="Multi Colored Chart"
              className="h-auto max-w-full"
            />
          </div>
          <div className="flex-grow">
            {' '}
            <p className="p-human-verified-title">Deeper stats</p>
            <p className="p-human-verified-body">
              Statistics that show trends in your learning history.
            </p>
          </div>
        </div>
        {/* <p className="text-center text-lg italic">Coming soon</p> */}
        {/*
        <Button className="bg-sky-800 text-white px-3 py-2 rounded-lg hover:bg-sky-900 w-full">
          Learn More
        </Button>
  */}
      </CardContent>
    </Card>
  )
}

export default FullReport
