import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Header from '../../components/Header/Header'
import '../../App.css'
import AudioAlert from '../../components/AudioAlert/AudioAlert'
import './RecordingPage.css'
import { AudioRecordingProps } from '@/types/types'
import ReadingCard from '@/components/ReadingCard/ReadingCard'
import LoadingPage from '../../pages/loading-page/LoadingPage'
import Alert from '@/components/alert/alert-dialog'

import { Card } from '@/components/ui/card'

const RecordingPage: React.FC = () => {
  const location = useLocation()
  const [showAudioAlert, setShowAudioAlert] = useState(false)
  const { result, topic, selectedReadingLevel } = location.state || {}
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [countdown, setCountdown] = useState<number | null>(null)
  const [isProcessing, setIsProcessing] = useState<boolean | null>(null)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertInfo, setAlertInfo] = useState({ title: '', message: '' })

  const handleShowAudioAlert = () => {
    setShowAudioAlert(true)
  }

  const handleMicrophoneError = (title: string, message: string) => {
    setAlertInfo({ title: title, message: message })
    setIsAlertOpen(true)
    setShowAudioAlert(false)
  }

  const showListeningAlert = () => {
    return (
      <div className="animate-fade-from-top w-full">
        <AudioAlert />
      </div>
    )
  }

  const audioRecordingProps: AudioRecordingProps = {
    story: result,
    showAudioAlert: handleShowAudioAlert,
    setIsLoading: setIsLoading,
    isLoading: isLoading,
    isAudioRecordingDisabled: false,
    countdown: countdown,
    setCountdown: setCountdown,
    isProcessing: isProcessing,
    setIsProcessing: setIsProcessing,
    onError: handleMicrophoneError,
  }

  return (
    <div className="recording-container mx-auto flex flex-col bg-blue-500 md:min-h-screen">
      {isLoading && <LoadingPage />}
      <div className="p-4 md:h-full md:w-full md:p-8">
        <Card className="flex flex-col gap-6 rounded-[10px] border bg-white p-4 text-card-foreground shadow md:h-full md:w-full md:p-8">
          <Header
            title="Start recording your story"
            line1="For best results: Speak directly into your microphone without muffling your voice, in a quiet area."
            modal={true}
            embed={true}
            useRelativePositioning={false}
          />
          <Alert
            title={alertInfo.title}
            message={alertInfo.message}
            isOpen={isAlertOpen}
            onOpenChange={setIsAlertOpen}
          />
          {showAudioAlert && showListeningAlert()}

          {/* Main content area */}
          <div className="border-1 mb-4 mt-4 border-gray-300 bg-white md:mb-0 md:mr-4 md:h-full md:w-full">
            {/* Story content */}
            {countdown !== null && (
              <div className="countdown-style">{countdown}</div>
            )}

            <ReadingCard
              _story={result}
              _level={selectedReadingLevel}
              _topic={topic}
              animation={true}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              audioRecordingProps={audioRecordingProps}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default RecordingPage
