import React from 'react'
import { MdRecordVoiceOver } from 'react-icons/md'
import { Alert, AlertTitle, AlertDescription } from '../ui/alert'

const AudioAlert = (): JSX.Element => {
  return (
    <Alert className="flex max-w-full items-start gap-3 border-t-4 border-green-600 bg-slate-900 px-4 py-3 shadow sm:max-w-md md:max-w-full lg:max-w-full">
      <div className="flex items-start gap-2 pt-px">
        <MdRecordVoiceOver className="h-6 w-6 text-white" />
      </div>

      <div className="flex shrink grow basis-0 flex-col items-start justify-start gap-1">
        <AlertTitle className="text-base font-bold leading-normal text-slate-50">
          We’re listening...
        </AlertTitle>
        <AlertDescription className="text-sm font-normal leading-tight text-slate-50">
          <span>Tap </span>
          <span className="font-bold">Stop Recording</span>
          <span> when you are done reading the story</span>
        </AlertDescription>
      </div>
    </Alert>
  )
}

export default AudioAlert
