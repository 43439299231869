import React, { useState, useMemo, forwardRef } from 'react'
import './ReadingCard.css'
import { AudioRecordingProps } from '@/types/types'
import AudioRecording from '../../components/AudioRecorder/AudioRecording'
import { createSession } from '@/services/api'
import {
  writeToLocalStorage,
  readFromLocalStorage,
} from '@/helpers/localstorage'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
} from '../ui/select'
import { MdInfo } from 'react-icons/md'
import { Input } from '../ui/input'
import { generateStory } from '@/services/api'
import { useNavigate } from 'react-router-dom'
import { Button } from '../ui/button'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Story } from '@/types/types'
import '../../pages/recording-page/RecordingPage.css'

interface ReadingCardProps {
  hideControls?: boolean
  _story?: Story
  _topic?: string
  _level?: string
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  isLoading?: boolean
  isAudioRecordingDisabled?: boolean
  animation?: boolean
  audioRecordingProps?: AudioRecordingProps
}

const ReadingCard = ({
  hideControls,
  _story,
  _topic,
  _level,
  setIsLoading,
  isLoading,
  isAudioRecordingDisabled,
  animation,
  audioRecordingProps,
}: ReadingCardProps): JSX.Element => {
  const [topic, setTopic] = useState(_topic || '')
  const [selectedReadingLevel, setSelectedReadingLevel] = useState(
    _level || '1-2'
  )
  const [story, setStory] = useState<Story | null>(_story || null)
  const navigate = useNavigate()

  const handleSelectChange = (value: string) => {
    setSelectedReadingLevel(value)
  }

  const setSession = async () => {
    try {
      const sessionId: string = await createSession()
      // console.log('Session ID:', sessionId)
      writeToLocalStorage('sessionId', sessionId)
    } catch (error) {
      console.error('Error creating session:', error)
    }
  }

  const handleCreateStory = async () => {
    let result: Story

    // await setSession()

    try {
      setIsLoading?.(true)
      if (!topic) {
        console.log('No topic')
        result = await generateStory(selectedReadingLevel)
      } else {
        console.log('Topic:', topic)
        result = await generateStory(selectedReadingLevel, topic)
      }

      setStory(result)

      navigate('/recording', {
        state: { result, topic, selectedReadingLevel },
      })
    } catch (err) {
      console.error('Error in creating a story', err)
    } finally {
      setIsLoading?.(false)
    }
  }

  const handleRandomize = async () => {
    // Reset the topic to an empty string
    setTopic('')

    // Generate a story with a random topic
    await handleCreateStory()
  }

  const levelLabels: any = {
    '1-2': 'Beginner',
    '3-4': 'Pre-intermediate',
    '5-6': 'Intermediate',
    '7-8': 'Upper-Intermediate',
    '9-10': 'Advanced',
    '11-12': 'Mastery',
  }

  const [hideStorySelectionControls, setHideStorySelectionControls] = useState<
    boolean | null
  >(null)

  const isCreateStoryButtonDisabled = useMemo(() => {
    return Boolean(
      !topic ||
        isLoading ||
        audioRecordingProps?.isProcessing ||
        audioRecordingProps?.countdown != null
    )
  }, [topic, isLoading, audioRecordingProps])

  const isRandomizeButtonDisabled = useMemo(() => {
    return Boolean(
      topic ||
        isLoading ||
        audioRecordingProps?.isProcessing ||
        audioRecordingProps?.countdown != null
    )
  }, [topic, isLoading, audioRecordingProps])

  return (
    <div className="flex flex-row flex-wrap gap-6 md:flex-nowrap">
      {!hideControls && (
        <div className="w-full rounded-lg border border-gray-200 bg-white p-4 md:w-[30%]">
          {!hideStorySelectionControls && (
            <>
              <div className="flex flex-col gap-1">
                <div className="flex flex-col gap-1">
                  <label className="text-base text-black">Story topic</label>
                  <div className="flex items-start justify-start gap-2">
                    <Input
                      className="md:w-200"
                      type="text"
                      placeholder="Enter topic"
                      value={topic}
                      onChange={(e) => setTopic(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          handleCreateStory()
                        }
                      }}
                    />
                  </div>
                  <div className="mb-4 mt-1 text-sm text-slate-600">
                    Get creative, it’s your story
                  </div>
                </div>
                <div className="mb-4 flex flex-col gap-1">
                  <div className="flex items-center">
                    <label className="mr-2 text-base text-black">
                      Reading level
                    </label>
                    <TooltipProvider>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger>
                          <MdInfo className="text-[#075985]" size={20} />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>
                            Based on <b>Canadian Language Benchmark (CLB)</b>
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                  <Select
                    value={selectedReadingLevel}
                    onValueChange={handleSelectChange}
                  >
                    <SelectTrigger className="lg:w-210 mb-4 h-10 max-w-full rounded-md border border-slate-200 bg-white md:mb-0 md:w-full">
                      {`${
                        levelLabels[selectedReadingLevel] ||
                        selectedReadingLevel
                      }`}
                    </SelectTrigger>

                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel className="pl-2">
                          Canadian Language Benchmark (CLB)
                        </SelectLabel>
                        <SelectItem value="1-2">
                          <span className="clb-level">CLB Level 1-2</span>{' '}
                          Beginner
                        </SelectItem>
                        <SelectItem value="3-4">
                          <span className="clb-level">CLB Level 3-4</span>{' '}
                          Pre-intermediate
                        </SelectItem>
                        <SelectItem value="5-6">
                          <span className="clb-level">CLB Level 5-6</span>{' '}
                          Intermediate
                        </SelectItem>
                        <SelectItem value="7-8">
                          <span className="clb-level">CLB Level 7-8</span>{' '}
                          Upper-Intermediate
                        </SelectItem>
                        <SelectItem value="9-10">
                          <span className="clb-level">CLB Level 9-10</span>{' '}
                          Advanced
                        </SelectItem>
                        <SelectItem value="11-12">
                          <span className="clb-level">CLB Level 11-12</span>{' '}
                          Mastery
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <Button
                  disabled={isCreateStoryButtonDisabled}
                  onClick={handleCreateStory}
                  className="btn-solid"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      handleCreateStory()
                    }
                  }}
                >
                  Create story
                </Button>
                <Button
                  disabled={isRandomizeButtonDisabled}
                  variant={'outline'}
                  onClick={handleRandomize}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      handleRandomize()
                    }
                  }}
                >
                  Randomize
                </Button>
              </div>
            </>
          )}
          {audioRecordingProps && (
            <>
              <AudioRecording
                story={audioRecordingProps.story}
                showAudioAlert={audioRecordingProps.showAudioAlert}
                setIsLoading={audioRecordingProps.setIsLoading}
                isLoading={audioRecordingProps.isLoading}
                isAudioRecordingDisabled={isAudioRecordingDisabled}
                countdown={audioRecordingProps.countdown}
                setCountdown={audioRecordingProps.setCountdown}
                isProcessing={audioRecordingProps.isProcessing}
                setIsProcessing={audioRecordingProps.setIsProcessing}
                hideStorySelectionControls={hideStorySelectionControls}
                setHideStorySelectionControls={setHideStorySelectionControls}
                onError={audioRecordingProps.onError}
              />
            </>
          )}
        </div>
      )}
      <div
        className={`${
          hideControls ? 'w-full' : 'md:w-[70%]'
        } flex-auto rounded-lg border border-gray-200 bg-white p-4`}
      >
        {story ? (
          <>
            <div className="flex flex-row rounded-lg border border-slate-300 bg-slate-100 px-1 py-2 text-xs">
              <div className="">
                Topic: {story.topic ? story.topic : 'No topic'}
              </div>
              <div className="flex-1"></div>
              <div>CLB Level: {levelLabels[selectedReadingLevel]}</div>
            </div>
            <div className="pb-4"></div>
            <p className="text-lg font-normal leading-loose tracking-widest text-gray-700">
              {story.content}
            </p>
          </>
        ) : (
          <>
            <img
              aria-label="Illustration: Writing in book"
              src="/Reading_a_book_about_space.png"
              alt="Reading a book about space"
              className="mx-auto mb-2 mt-4"
            />
            <div className="text-center text-xl font-bold text-gray-700">
              Enter a topic, or
            </div>
            <div className="flex items-center justify-center">
              <Button
                disabled={isRandomizeButtonDisabled}
                onClick={handleRandomize}
                className="btn-solid mt-4 max-w-64"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    handleRandomize()
                  }
                }}
              >
                Let us create one for you
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ReadingCard
