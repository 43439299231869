import React from 'react'
import './LoadingPage.css'

const LoadingPage: React.FC = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="loading-page-div z-60 absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-center justify-center">
        <div className="loading-page-div-content flex flex-col">
          <span className="loading-page-div-content-span">
            <div className="loading-page-div-content-line"></div>
            <div className="loading-page-div-content-line"></div>
            <div className="loading-page-div-content-line"></div>
          </span>
          <p>Loading</p>
        </div>
      </div>
    </div>
  )
}

export default LoadingPage
