import React, { useState } from 'react'
import Header from '../../components/Header/Header'
import './Comprehension.css'
import '../../App.css'
import { QuestionData } from '../../types/types'
import RenderQuestions from '../../components/Question/RenderedQuestion'
import AudioAlert from '../../components/AudioAlert/AudioAlert'
import { useLocation } from 'react-router-dom'
import { Card } from '@/components/ui/card'
import ReadingCard from '@/components/ReadingCard/ReadingCard'
import LoadingPage from '../../pages/loading-page/LoadingPage'

const Comprehension: React.FC = () => {
  const location = useLocation()
  const { story } = location.state || {}

  const questions: QuestionData[] = story?.questions || []
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <div className="recording-container mx-auto flex flex-col bg-blue-500 md:min-h-screen">
      {isLoading && <LoadingPage />}
      <div className="p-4 md:h-full md:w-full md:p-8">
        <Card className="flex flex-col gap-6 rounded-[10px] border bg-white p-4 text-card-foreground shadow md:h-full md:w-full md:p-8">
          <Header
            title="Comprehension"
            line1="Answer a few questions about the story you read."
            link="We will show you results once you are done."
            embed={true}
            useRelativePositioning={false}
          />

          {/* Main content area */}
          <div className="animate-fade-right flex w-full flex-grow flex-col sm:flex-row">
            <div className="box border-1 mb-4 border-gray-300 bg-white p-4 sm:mb-0 md:w-[70%]">
              {/* Story content */}
              <ReadingCard
                hideControls
                _story={story}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            </div>
            <div className="box border-gray-300 bg-white p-4 md:w-[30%]">
              <RenderQuestions
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                questions={questions}
                story={story}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
export default Comprehension
