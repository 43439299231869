import React from 'react'
import './Highlight.css'

interface ReadingEntry {
  word: string
  position: number
  type: string
}

interface HighlightProps {
  text: string
  reading: ReadingEntry[]
}

interface HighlightDelimiterProps {
  text: string
}

const HighlightByPosition: React.FC<HighlightProps> = ({ text, reading }) => {
  const createHighlightedText = (text: string, reading: ReadingEntry[]) => {
    // Sort reading entries by position
    const sortedReading = [...reading].sort((a, b) => a.position - b.position)
    // Initialize an array to keep track of positions to highlight
    const positionsToHighlight = new Set(
      sortedReading.map((item) => item.position)
    )

    const regex = /(\w+|\W+)/g
    let match
    let currentPosition = 0
    let highlightedText = ''

    while ((match = regex.exec(text)) !== null) {
      if (
        /\w+/.test(match[0]) &&
        !isNaN(currentPosition) &&
        positionsToHighlight.has(currentPosition)
      ) {
        highlightedText += `<mark>${match[0]}</mark>`
      } else {
        highlightedText += match[0]
      }
      if (/\w+/.test(match[0])) currentPosition++
    }

    return { __html: highlightedText }
  }

  return <div dangerouslySetInnerHTML={createHighlightedText(text, reading)} />
}

const HighlightByDelimiter: React.FC<HighlightDelimiterProps> = ({ text }) => {
  const createHighlightedText = (text: string) => {
    // Regex for different types of highlights
    const mispronounced = /\*(.*?)\*/g
    const skipped = /_(.*?)_/g
    const added = /\+(.*?)\+/g

    // Replace each type of highlight with appropriate markup
    const highlightedText = text
      .replace(mispronounced, '<mark class="mispronounced">$1</mark>')
      .replace(skipped, '<mark class="skipped">$1</mark>')
      .replace(added, '<mark class="added">$1</mark>')

    return { __html: highlightedText }
  }

  return <div dangerouslySetInnerHTML={createHighlightedText(text)} />
}

export default HighlightByPosition
export { HighlightByDelimiter }
