import { type User, type CustomUser, Story, QuestionData } from '../types/types'
import { getCookie, readFromLocalStorage } from '../helpers/localstorage'
import { store } from '../shared/store'
import { put } from '@vercel/blob'
// import dotenv from 'dotenv'

// dotenv.config()

const BASE_URL =
  process.env.REACT_APP_BACKEND_URL ??
  'https://readiverse-service.vercel.app/api'

interface RequestOptions {
  method: string
  headers: { [key: string]: string }
  body?: string | FormData | undefined
}

const buildHeaders = (token: string, isFormData = false) => {
  const headers: { [key: string]: string } = {
    Authorization: `Bearer ${token}`,
  }

  if (!isFormData) {
    headers['Content-Type'] = 'application/json'
  }

  return headers
}

const callApi = async (
  url: string,
  method: string,
  payload: any,
  customHeaders = {}
) => {
  const token: string = getCookie('token') || ''
  const isFormData = payload instanceof FormData
  const headers = { ...buildHeaders(token, isFormData), ...customHeaders }

  const options: RequestOptions = {
    method,
    headers,
    body: isFormData ? payload : JSON.stringify(payload),
  }

  if (method === 'GET') {
    delete options.body
  }

  try {
    const response = await fetch(`${BASE_URL}${url}`, options)

    if (!response.ok) {
      const errorBody = await response.text()
      throw new Error(`API request failed: ${response.status} - ${errorBody}`)
    }

    return await response.json()
  } catch (error) {
    console.error('API Error:', error)
    throw new Error('An error occurred during the API request')
  }
}

export const transcribeAudio = async (audioBlob: any) => {
  console.log('API: Transcribing audio...')
  const email = store.getState().auth.user?.email ?? ''
  const formData = new FormData()
  formData.append(
    'audio',
    new File([audioBlob], 'recording.wav', { type: 'audio/wav' })
  )
  formData.append('email', email)

  const sessionId = readFromLocalStorage('sessionId')
  const audioFile = new File([audioBlob], 'recording.wav', {
    type: 'audio/wav',
  })
  const { url: blobUrl } = await put(
    `transcriptions/${sessionId}/recording.wav`,
    audioFile,
    {
      access: 'public',
      token: 'vercel_blob_rw_q4Z2dpjB4A1laeee_ny1ytczwCtx8UtizJSelKg6nVfu4eU', // to be removed
    }
  )

  // retrieve the session id from redux or local storage - Keeping this for testing purposes
  const url = `/transcribe?session_id=${sessionId}`

  try {
    const response = await callApi(url, 'POST', formData)
    // console.log('Transcript:', response)
    return response
  } catch (error: any) {
    console.error('Transcribe Audio Error:', error.message)
    throw new Error('Failed to transcribe audio')
  }
}

export const generateStory = async (
  level: string,
  topic?: string,
  locale?: string,
  source?: string
): Promise<Story> => {
  console.log('API: Generating story...')
  const payload: { level: string; locale: string; topic?: string } = {
    level,
    locale: locale ?? 'en',
  }

  if (topic !== undefined && topic !== null && topic !== '') {
    payload.topic = topic
  }

  // retrieve the session id from redux or local storage - Keeping this for testing purposes
  const sessionId = readFromLocalStorage('sessionId')
  const url = `/story?session_id=${sessionId}`

  try {
    const response = await callApi(url, 'POST', payload)
    // console.log('Story:', response)
    return response
  } catch (error: any) {
    console.error('Generate Story Error:', error.message)
    throw new Error('Failed to generate story')
  }
}

export const createSession = async (): Promise<string> => {
  console.log('API: Creating session...')
  const url = '/session/create'

  try {
    const response = await callApi(url, 'POST', {})
    const sessionId = response.session_id as string
    return sessionId
  } catch (error: any) {
    console.error('Create Session Error:', error.message)
    throw new Error('Failed to create session')
  }
}

export const getReport = async (questionsAndAnswers: any): Promise<any> => {
  console.log('API: Getting report...')
  // retrieve the session id from redux or local storage - Keeping this for testing purposes
  const sessionId = readFromLocalStorage('sessionId')
  const url = `/report?session_id=${sessionId}`

  try {
    const response = await callApi(url, 'POST', questionsAndAnswers)
    return response
  } catch (error: any) {
    console.error('Get Report Error:', error.message)
    throw new Error('Failed to get report')
  }
}
export const login = async (email: string, password: string) => {
  try {
    return await callApi('/login', 'POST', { email, password })
  } catch (error: any) {
    console.error('Login Error:', error.message)
    throw new Error('Failed to login')
  }
}

export const addSocialUser = async (user: CustomUser) => {
  const { uid, email, displayName, source } = user

  try {
    return await callApi('/users/social', 'POST', {
      uid,
      source,
      email,
      displayName,
    })
  } catch (error: any) {
    console.error('Add Social User Error:', error.message)
    throw new Error('Failed to save the social user')
  }
}
