import React from 'react'
import './Header.css'
import { Button } from '../ui/button'
import { X } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { clearLocalStorage } from '@/helpers/localstorage'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'

interface HeaderProps {
  title: string
  line1: string
  line2?: string
  link?: string
  linkRef?: string
  modal?: boolean
  embed?: boolean
  useRelativePositioning?: boolean
}

const Header: React.FC<HeaderProps> = ({
  title,
  line1,
  line2,
  link,
  linkRef,
  modal,
  embed,
  useRelativePositioning = true,
}) => {
  const navigate = useNavigate()

  const handleClose = () => {
    clearLocalStorage()
    navigate('/')
  }

  const containerClasses = 'flex flex-col bg-white text-white w-[1200px] gap-3'
  const embeddedClasses = embed ? '' : 'p-4 shadow-5xl border-2'

  const positionClass = useRelativePositioning ? 'relative-positioning' : ''

  return (
    <div
      id="header-container"
      className={`${containerClasses} ${embeddedClasses} ${positionClass}`}
    >
      <div className="inline-flex items-start justify-start gap-2 self-stretch">
        <div className="shrink-0 flex-grow basis-0 text-left font-bold leading-[48px] text-black sm:text-2xl md:text-5xl">
          {' '}
          {/* Modified class */}
          {title}
        </div>
        <div className="flex-1"></div>
        <Button variant="outline" size="icon" onClick={handleClose}>
          <X className="h-4 w-4" />
        </Button>
      </div>

      <div className="self-stretch text-left font-['Inter'] text-lg font-normal leading-tight text-black">
        {' '}
        {/* Modified class */}
        {line1}
      </div>

      {line2 && (
        <div className="self-stretch text-left font-['Inter'] text-lg font-normal leading-tight text-black">
          {' '}
          {/* Modified class */}
          {line2}
        </div>
      )}
      {link && (
        <a href={linkRef} className="mt-2 text-xs text-blue-500 md:text-sm">
          {link}
        </a>
      )}

      {modal && (
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <a href="#" className="text-blue-500 hover:text-blue-700">
              Having troubles? Use our recording tips to get the best results.
            </a>
          </AlertDialogTrigger>
          <AlertDialogContent className="sm:w-full">
            {' '}
            {/* Modified class */}
            <AlertDialogHeader>
              <AlertDialogTitle>Recording tips</AlertDialogTitle>
              <AlertDialogDescription>
                <ul className="pl-10">
                  <li>
                    Get close to mic, but not close enough to muffle your voice
                  </li>
                  <li>
                    Stand still while recording, and try not to move your hand
                  </li>
                  <li>
                    Distance yourself from other noise sources, like traffic or
                    music
                  </li>
                  <li>Mute your device</li>
                  <li>Try using an external microphone</li>
                </ul>
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogAction className="btn-solid">
                Got It
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </div>
  )
}

export default Header
