export interface User {
  token: string
  success: boolean
  user: CustomUser
}

export interface CustomUser {
  uid: string
  email: string | null
  first_name?: string
  last_name?: string
  displayName: string | null
  role?: string
  created_at?: string
  updated_at?: string
  source: string
  photoURL?: string | null
}

export interface Evaluation {
  transcript: string
  score: number
  grade: string
  analysis: string
}

export interface AudioRecorder {
  recording: boolean
  startRecording: () => void
  stopRecording: () => Promise<Evaluation | null>
  audioRef: React.MutableRefObject<HTMLAudioElement | null>
}

export type AudioRecordingProps = {
  story: Story | null
  showAudioAlert: () => void
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  isLoading?: boolean
  isAudioRecordingDisabled?: boolean
  countdown: number | null
  setCountdown: React.Dispatch<React.SetStateAction<number | null>>
  isProcessing: boolean | null
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean | null>>
  hideStorySelectionControls?: boolean | null
  setHideStorySelectionControls?: React.Dispatch<
    React.SetStateAction<boolean | null>
  >

  onError: (title: string, message: string) => void
}

export interface AuthAction {
  type: string
  payload?: CustomUser | null
}

export interface AuthState {
  user?: CustomUser | null
  isLoggedIn: boolean
}

export interface QuestionData {
  type: 'text' | 'textarea' | 'multiple-choice'
  questionText: string
  options?: string[]
  correctAnswer?: string
  userAnswer?: string
}

export type Story = {
  content: string
  id: string
  topic: string | null
  questions: QuestionData[]
}

export const SET_USER = 'SET_USER'
export const LOGOUT = 'LOGOUT'
