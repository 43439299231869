import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import Question from './Question'
import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { useNavigate } from 'react-router-dom'
import { QuestionData } from '@/types/types'
import { getReport } from '@/services/api'

interface RenderQuestionsProps {
  questions: QuestionData[]
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  isLoading?: boolean
  story: any
}

const RenderQuestions: React.FC<RenderQuestionsProps> = ({
  questions,
  setIsLoading,
  isLoading,
  story,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [textAreaValue, setTextAreaValue] = useState('')
  const [answers, setAnswers] = useState<{ [key: number]: string }>({})
  const [selectedOption, setSelectedOption] = useState<string>('')
  const navigate = useNavigate()

  useEffect(() => {
    if (
      questions[currentQuestionIndex].type === 'text' ||
      questions[currentQuestionIndex].type === 'textarea'
    ) {
      setInputValue(answers[currentQuestionIndex] || '')
    } else if (questions[currentQuestionIndex].type === 'multiple-choice') {
      setSelectedOption(answers[currentQuestionIndex] || '')
    }
  }, [currentQuestionIndex, questions, answers])

  const updateAnswer = (index: number, value: string) => {
    setAnswers((prev) => ({ ...prev, [index]: value }))
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setInputValue(newValue)
    updateAnswer(currentQuestionIndex, newValue)
  }

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value
    setTextAreaValue(newValue)
    updateAnswer(currentQuestionIndex, newValue)
  }

  const handleRadioGroupChange = (optionValue: string) => {
    setSelectedOption(optionValue)
    updateAnswer(currentQuestionIndex, optionValue)
  }

  const handlePrevious = (): void => {
    setCurrentQuestionIndex((prev) => Math.max(prev - 1, 0))
  }

  const handleNext = (): void => {
    setCurrentQuestionIndex((prev) => Math.min(prev + 1, questions.length - 1))
  }

  const handleSkip = (): void => {
    // Handle skip action
  }

  const handleGoToReport = async (): Promise<void> => {
    setIsLoading?.(true)
    // console.log('Go to report')
    // console.log('User Responses:', answers)

    //update question with responses here
    const updatedQuestions = questions.map((question, index) => {
      return { ...question, response: answers[index] }
    })

    // console.log('Updated Questions:', updatedQuestions)

    // api call here
    const report = await getReport(updatedQuestions)

    setIsLoading?.(false)
    // Handle go to report action
    navigate('/report', { state: { data: report, story: story } })
  }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    questionType: string,
    handleNext: () => void
  ) => {
    if (e.key === 'Enter') {
      switch (questionType) {
        case 'text':
        case 'textarea':
          handleNext() // Move to the next question
          break

        default:
          break
      }
    }
  }

  const renderQuestionComponent = (
    question: QuestionData
  ): JSX.Element | null => {
    switch (question.type) {
      case 'text':
        return (
          <div className="flex flex-col gap-2">
            <div className="text-base font-normal leading-normal text-black">
              {question.questionText}
            </div>
            <Input
              type="text"
              className="w-full rounded-md border border-slate-300 bg-white p-3 text-black"
              value={answers[currentQuestionIndex] || ''}
              onChange={handleInputChange}
              onKeyDown={(e) => handleKeyDown(e, question.type, handleNext)}
            />
          </div>
        )
      case 'textarea':
        return (
          <div className="flex flex-col gap-2">
            <div className="text-base font-normal leading-normal text-black">
              {question.questionText}
            </div>
            <textarea
              className="h-[68px] w-full rounded-md border border-slate-300 bg-white px-3 py-2 text-black"
              value={answers[currentQuestionIndex] || ''}
              onChange={handleTextAreaChange}
              onKeyDown={(e) => handleKeyDown(e, question.type, handleNext)}
            ></textarea>
          </div>
        )
      case 'multiple-choice':
        return (
          <div className="flex flex-col gap-4">
            <div className="text-base font-normal leading-normal text-black">
              {question.questionText}
            </div>
            <div className="flex flex-col">
              {question.options?.map((option, index) => (
                <div key={index} className="my-2 flex items-start space-x-2">
                  <input
                    id={`option-${index}-${currentQuestionIndex}`}
                    type="radio"
                    name={`question-${currentQuestionIndex}`}
                    value={option}
                    checked={answers[currentQuestionIndex] === option}
                    onChange={() => handleRadioGroupChange(option)}
                    className="form-radio mt-1 h-4 w-4 rounded border-gray-300"
                  />
                  <label
                    className="text-md flex-1 text-gray-700"
                    htmlFor={`option-${index}-${currentQuestionIndex}`}
                  >
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )

      default:
        return null
    }
  }

  if (currentQuestionIndex >= questions.length) {
    return (
      <div className="text-center">
        <h1>Your report is almost ready!</h1>
        <Button onClick={handleGoToReport}>Go to report</Button>
      </div>
    )
  }

  const currentQuestion = questions[currentQuestionIndex]

  return (
    <Question
      questionNumber={currentQuestionIndex + 1}
      totalQuestions={questions.length}
      onSkip={handleSkip}
      onPrevious={handlePrevious}
      onNext={handleNext}
      gotToReport={handleGoToReport}
    >
      {renderQuestionComponent(currentQuestion)}
    </Question>
  )
}

export default RenderQuestions
