import React from 'react'
import { Link } from 'react-router-dom'
import { buttonVariants } from '../../../../components/ui/button'

const HomeHeader = () => {
  return (
    <header className="p-4">
      <div className="container mx-auto flex items-center justify-between">
        {/* Logo on the left */}
        <div className="flex items-center"></div>

        {/* Outline button on the right 
        <Link className={buttonVariants({ variant: 'outline' })} to="/login">
          Click here
        </Link>
        */}
      </div>
    </header>
  )
}

export default HomeHeader
