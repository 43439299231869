import React, { useEffect, useRef, useState } from 'react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import DictionaryItem from 'src/components/DictionaryItem/DictionaryItem'
import { fetchDictionaryEntries } from 'src/services/dictionary'
import HighlightByPosition, {
  HighlightByDelimiter,
} from 'src/components/Highlight/Highlight'
import './ReadingInsights.css'
import { ReportDataType } from '../types'
import { SharedStore } from '@/services/shared-store'

interface readingRow {
  word: string
  position: number
  type: string
}

interface DictionaryEntry {
  word: string
  definition: string
  audioUrl?: string
}

interface ReadingInsightsProps {
  // readingRows: readingRow[]
  // story: string
  data: ReportDataType
}
export function ReadingInsights(props: ReadingInsightsProps) {
  // const { story, transcript } = props
  const { story, transcript } = props.data
  const sharedStore = SharedStore.getInstance()
  const sessionId = localStorage.getItem('sessionId')?.toString() || ''
  // console.log('sessionId:', sessionId)
  const audioUrl = sharedStore.getItem(sessionId)
  // console.log('audioUrl:', audioUrl)
  // console.log('all items i the store: ', sharedStore.getAllItems())

  // write a function that takes the transcript and extract all the words surrounded by asterisks and return them in an array
  const extractedMispronouncedWords = () => {
    const regex = /\*([^*]+)\*/g
    const matches = story.match(regex)
    if (matches) {
      return matches.map((match) => match.slice(1, -1))
    }
    return []
  }
  const mispronouncedWords = extractedMispronouncedWords()

  const issueCount = props.data.mistakes
  const [dictionaryEntries, setDictionaryEntries] = useState<DictionaryEntry[]>(
    []
  )

  useEffect(() => {
    // Assuming readingRows are fetched/updated somewhere in your component
    // Fetch dictionary entries when readingRows change
    const fetchData = async () => {
      try {
        const entries = await fetchDictionaryEntries(mispronouncedWords)
        setDictionaryEntries(entries)
      } catch (error) {
        console.error('Error fetching dictionary entries:', error)
      }
    }

    fetchData()
  }, []) // Make sure to include any dependencies that might change

  return (
    <Card className="mb-6">
      <CardHeader>
        <div className="flex items-center justify-between">
          <h2>Reading Insights</h2>
          <Badge className="badge bg-red-500">{issueCount} Issues</Badge>
        </div>
      </CardHeader>
      <CardContent>
        <Card className="mb-6">
          <CardContent className="p-5">
            <h2 className="mb-4 text-lg font-bold">Story</h2>
            <HighlightByDelimiter text={story} />
            <hr className="mb-4 mt-4" />
            <h2 className="mb-4 text-lg font-bold">Transcript</h2>
            <HighlightByDelimiter text={transcript} />
          </CardContent>
          {audioUrl && (
            <audio controls className="m-6">
              <source src={audioUrl} type="audio/webm; codecs=opus" />
              Your browser does not support the audio element.
            </audio>
          )}
        </Card>

        <Card className="mb-6">
          <CardContent className="p-0">
            <Accordion type="single" collapsible className="w-full">
              {mispronouncedWords.map((row, index) =>
                row !== '-' ? (
                  <AccordionItem
                    key={index}
                    value={`item-${index}`}
                    className="p-4"
                  >
                    <AccordionTrigger>
                      <div className="flex items-center space-x-2">
                        <div className="reading-word">{row}</div>
                        <span>{'mispronounced'}</span>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <DictionaryItem
                        entry={dictionaryEntries[index]}
                      ></DictionaryItem>
                    </AccordionContent>
                  </AccordionItem>
                ) : null
              )}
            </Accordion>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  )
}

export default ReadingInsights
