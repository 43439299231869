import jwtDecode from 'jwt-decode'
import { type CustomUser } from '../types/types'

export const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop()?.split(';').shift() ?? null
  return null
}

export const checkTokenExpiration = (token: string): boolean => {
  try {
    const decoded: any = jwtDecode(token)
    const currentTime = Date.now() / 1000
    return decoded.exp > currentTime
  } catch {
    return false
  }
}

export const setAuthCookies = (token: string, user: CustomUser): void => {
  const ENV = process.env.REACT_APP_ENVIRONMENT

  if (ENV === 'LOCAL') {
    console.log('development mode')
    document.cookie = `token=${token}; Path=/;`
    document.cookie = `user=${JSON.stringify(user)}; Path=/;`
    return
  }

  document.cookie = `token=${token}; Path=/; Secure; SameSite=Strict`
  document.cookie = `user=${JSON.stringify(
    user
  )}; Path=/;  Secure; SameSite=Strict`
}

export const removeAuthCookies = (): void => {
  document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  document.cookie = 'user=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  window.location.reload()
}

export const readFromLocalStorage = (key: string): string | null => {
  try {
    return localStorage.getItem(key)
  } catch (error) {
    console.error('Could not read from localStorage:', error)
    return null
  }
}

export const writeToLocalStorage = (key: string, value: string): void => {
  try {
    localStorage.setItem(key, value)
  } catch (error) {
    console.error('Could not write to localStorage:', error)
  }
}

export const clearLocalStorage = (): void => {
  try {
    localStorage.clear()
  } catch (error) {
    console.error('Could not clear localStorage:', error)
  }
}
