import React from 'react'
import {
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'
import './Footer.css'

const Footer = () => {
  return (
    <div
      id="report-footer"
      className="flex flex-col bg-neutral-900 sm:px-4 md:flex-row"
    >
      <div className="flex-1 px-4 py-12">
        <div className="mx-auto flex w-full flex-col md:max-w-[380px]">
          <h2>Disclaimer</h2>
          {/* <div>
            <Label htmlFor="email" className="text-left">
              Email
            </Label>
            <Input id="email" defaultValue="Your Email Address" />
          </div> */}
          <p className="mb-6 mt-6">
            This report is generated with the help of Artificial Intelligence
            (AI) to analyze your test results and provide tailored insights.
            While our AI aims for accuracy, please consider its recommendations
            as part of a comprehensive learning approach.
          </p>
          {/* <button
            type="submit"
            className="bg-sky-800 text-white px-3 py-2 rounded-lg hover:bg-sky-900 w-full"
          >
            Sign Up
          </button> */}
        </div>
      </div>
      <div className="flex-1 px-4 py-12">
        <div className="gap mx-auto flex w-full flex-col md:max-w-[460px]">
          <h2 style={{ marginBottom: '24px' }}>Embark on a language journey</h2>
          <p className="mb-24">
            Readiverse is free! We want you to help you improve. If you have
            questions or feedback, get in touch with us at support@readiverse.ca
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
